import { useWizard } from "react-use-wizard";
import { useCheckoutContext } from "context/CheckoutContext";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import SearchReferral from "./exigo/SearchReferral";

const SummaryTable = ({ contact, referral, showReferralOptions }) => {
  const { checkout } = useCheckoutContext();
  const { goToStep, activeStep } = useWizard();

  return (
    <>
      <div className="mt-4">
        <SearchReferral referral={referral} showReferralOptions={showReferralOptions} />
        {activeStep >= 1 && <Container />}
        {activeStep >= 2 && (
          <Container>
            <div className="horizontalRuler my-2" />
            <Row>
              <Col xs={3} md={3}>
                Ship to
              </Col>
              <Col xs={6} md={7}>
                {checkout.shipping.country}, {checkout.shipping.city},{" "}
                {checkout.shipping.streetAddress}
              </Col>
              <Col xs={2} md={2}>
                <button className="referredButton" onClick={() => goToStep(1)}>
                  <u>Change</u>
                </button>
              </Col>
            </Row>
          </Container>
        )}
        {activeStep >= 3 && (
          <Container>
            <div className="horizontalRuler my-2" />
            <Row>
              <Col xs={3} md={3}>
                Subscription
              </Col>
              <Col xs={6} md={7}>
                {checkout.subscription.frequency},{" "}
                {checkout.subscription.startDate}
              </Col>
              <Col xs={2} md={2}>
                <button className="referredButton" onClick={() => goToStep(2)}>
                  <u>Change</u>
                </button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

export default SummaryTable;
