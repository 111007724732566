import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Overlay, Tooltip } from "react-bootstrap";

import SummaryTable from "components/SummaryTable";
import { useWizard } from "react-use-wizard";
import { useCheckoutContext } from "context/CheckoutContext";
import { FloatingLabel } from "react-bootstrap";
import { sendErrorToSlack } from "../services/slackService";
import "./Subscription.styles.css";

// Move formatDateToString outside component for better performance
const formatDateToString = (date) => {
  // Create a new date at noon UTC
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0)
  );
  return utcDate.toISOString().split("T")[0];
};

// Get default dates for date range
const getDefaultDates = () => {
  const currentDate = new Date();
  const minDate = new Date(currentDate);
  minDate.setDate(currentDate.getDate() + 30);

  // Adjust to 27th if necessary
  if (minDate.getDate() >= 28) {
    minDate.setDate(27);
  }

  const minDateString = formatDateToString(minDate);

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 60);
  if (maxDate.getDate() >= 28) {
    maxDate.setDate(27);
  }
  const maxDateString = formatDateToString(maxDate);

  return { minDateString, maxDateString };
};

const Subscription = ({ cart }) => {
  const { nextStep } = useWizard();
  const { checkout, setCheckout } = useCheckoutContext();

  // Actualizar el useEffect que maneja los estilos
  useEffect(() => {
    // Función para crear y aplicar estilos al calendario
    const applyCalendarStyles = () => {
      const dateInput = document.querySelector('input[type="date"]');
      if (!dateInput) return;

      // Observar cambios en el DOM para detectar cuando se abre el calendario
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length) {
            const calendar = document.querySelector(".calendar-popup");
            if (calendar) {
              const allDates = calendar.querySelectorAll("td");
              allDates.forEach((dateCell) => {
                const dateText = dateCell.textContent;
                if (parseInt(dateText) >= 28) {
                  dateCell.style.color = "#ccc";
                  dateCell.style.backgroundColor = "#f5f5f5";
                  dateCell.style.pointerEvents = "none";
                  dateCell.style.opacity = "0.5";
                  dateCell.setAttribute("disabled", "true");
                }
              });
            }
          }
        });
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });

      // Create global styles
      const style = document.createElement("style");
      style.textContent = `
        /* Calendar native styles */
        input[type="date"]::-webkit-calendar-picker-indicator {
          opacity: 1;
        }

        input[type="date"]::-webkit-inner-spin-button {
          display: none;
        }

        /* Styles for days after the 27th */
        td[data-value="28"],
        td[data-value="29"],
        td[data-value="30"],
        td[data-value="31"] {
          color: #ccc !important;
          background-color: #f5f5f5 !important;
          pointer-events: none !important;
          opacity: 0.5 !important;
          cursor: default !important;
        }

        /* Disable hover on non-selectable days */
        td[data-value="28"]:hover,
        td[data-value="29"]:hover,
        td[data-value="30"]:hover,
        td[data-value="31"]:hover {
          background-color: #f5f5f5 !important;
          cursor: not-allowed !important;
        }
      `;
      document.head.appendChild(style);

      // Add listener for calendar opening event
      dateInput.addEventListener("click", () => {
        setTimeout(() => {
          const calendar = document.querySelector(".calendar-popup");
          if (calendar) {
            const allDates = calendar.querySelectorAll("td");
            allDates.forEach((dateCell) => {
              const dateText = dateCell.textContent;
              if (parseInt(dateText) >= 28) {
                dateCell.style.color = "#ccc";
                dateCell.style.backgroundColor = "#f5f5f5";
                dateCell.style.pointerEvents = "none";
                dateCell.style.opacity = "0.5";
                dateCell.setAttribute("disabled", "true");
              }
            });
          }
        }, 100);
      });

      return () => {
        observer.disconnect();
        document.head.removeChild(style);
      };
    };

    applyCalendarStyles();
  }, []);

  // Get dates once and memoize them
  const { minDateString, maxDateString } = React.useMemo(
    () => getDefaultDates(),
    []
  );

  useEffect(() => {
    // Initialize subscription with default values
    setCheckout({
      ...checkout,
      currentStep: 2,
      subscription: {
        ...checkout.subscription,
        frequency: "Monthly",
        startDate: minDateString,
      },
    });
  }, [minDateString]);

  const {
    subscription: { frequency, startDate },
  } = checkout;

  // Function to filter dates after 27th
  const filterDates = (date) => {
    return true; // Allow all dates to be shown
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      nextStep();
    } catch (error) {
      sendErrorToSlack(error, {
        component: "Subscription",
        userEmail: cart.customer?.email,
        cartToken: checkout.cartToken,
      });
    }
  };

  // Function to update context with new values
  const updateContext = (target, value) => {
    let subscriptionForm = checkout.subscription;
    subscriptionForm[target] = value;

    if (target === "frequency") {
      subscriptionForm.frequency = "Monthly";
    }

    setCheckout({ ...checkout, subscription: subscriptionForm });
  };

  // Add states for tooltip
  const [showTooltip, setShowTooltip] = useState(false);
  const [targetDate, setTargetDate] = useState(null);
  const [targetRef, setTargetRef] = useState(null);

  // Function to handle date selection
  const handleDateChange = (selectedDate) => {
    // Create a new date object at noon UTC to avoid timezone issues
    const date = new Date(
      Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        12,
        0,
        0
      )
    );

    const day = date.getUTCDate();
    let finalDate = new Date(date);

    if (day >= 28) {
      finalDate.setUTCDate(27);
      setTargetDate(date);
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 3000);
    } else if (day === 1) {
      finalDate = date;
      setTargetDate(date);
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 3000);
    }

    updateContext("startDate", formatDateToString(finalDate));
  };

  return (
    <div className="subscription-component">
      <div className="wizardContainer">
        <button className="wizardLink">Cart</button>
        {">"}
        <button className="wizardLink">Account Details</button>
        {">"}
        <button className="wizardLink">Shipping Address</button>
        {">"}
        <button className="wizardLink">Subscription</button>
        {">"}
        <button className="wizardLink" disabled>
          Payment
        </button>
      </div>

      <SummaryTable
        contact={cart.customer.email}
        referral={cart.cart.referral}
      />

      <Row className="mb-3">
        <Col md="12">
          <h2 className="marginBottomSmall marginTopSmall">Subscription</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <div className="subscription-frequency-text">
                <p className="mb-0">
                  Subscription Frequency: <strong>Monthly</strong>
                </p>
                <div className="horizontalRuler my-3" />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <FloatingLabel
                controlId="floatingSelect"
                label="When should it start?"
                className="mb-3 subscription-date-floating-label"
              >
                <div ref={setTargetRef}>
                  <DatePicker
                    selected={
                      startDate
                        ? new Date(startDate + "T12:00:00Z")
                        : new Date(minDateString + "T12:00:00Z")
                    }
                    onChange={handleDateChange}
                    minDate={new Date(minDateString + "T12:00:00Z")}
                    maxDate={new Date(maxDateString + "T12:00:00Z")}
                    filterDate={filterDates}
                    dateFormat="yyyy-MM-dd"
                    className="form-control subscription-date-input"
                    required
                    placeholderText="Select a date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    inline={false}
                    popperClassName="datepicker-popper subscription-datepicker"
                    customInput={
                      <Form.Control
                        style={{
                          cursor: "pointer",
                          backgroundColor: "white",
                        }}
                      />
                    }
                  />
                </div>
              </FloatingLabel>
              <Form.Text className="text-muted">
                For optimal processing, monthly auto-orders will be processed no later than the 27th
              </Form.Text>
            </Form.Group>

            <div className="flex JCEnd">
              <Button
                className="btn-bold oola-main-color"
                variant="primary"
                type="submit"
              >
                Continue
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

      <Overlay
        show={showTooltip}
        target={targetRef}
        placement="top"
        containerPadding={20}
      >
        <Tooltip id="date-tooltip" className="custom-tooltip">
          {targetDate && targetDate.getDate() === 1
            ? "Orders on the 1st will be processed on the 27th of the previous month"
            : "Orders after the 27th will be processed on the 27th of the same month"}
        </Tooltip>
      </Overlay>
    </div>
  );
};

export default Subscription;
