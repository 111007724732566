import { SLACK_WEBHOOK_URL } from "../config";

export const sendErrorToSlack = async (error, context = {}) => {
  try {
    // Format error message for Slack
    const payload = {
      text:
        `🚨 *Frontend Error Detected* 🚨\n\n` +
        `💥 *Error:*\n${error?.message || JSON.stringify(error)}\n\n` +
        `🔍 *Component:*\n${context.component || "Unknown"}\n\n` +
        `👤 *User:*\n${context.userEmail || "Unknown"}\n\n` +
        `🛒 *Cart Token:*\n\`${context.cartToken || "Unknown"}\`\n\n` +
        `_Reported from frontend at ${new Date().toLocaleString()}_`,
    };

    await fetch(SLACK_WEBHOOK_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    return true;
  } catch (slackError) {
    return false;
  }
};
