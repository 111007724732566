import { useState, useEffect } from "react";
import { useCheckoutContext } from "context/CheckoutContext";
import { API_LOCAL_BASE_URL } from "config";
import { sendErrorToSlack } from "services/slackService";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Container, Card, ListGroup, Button } from "react-bootstrap";

const SearchReferral = ({ referral, showReferralOptions }) => {
  const [searching, setSearching] = useState(false);
  const { checkout, setCheckout } = useCheckoutContext();
  const { referredId } = checkout;
  const [referrals, setReferrals] = useState([]);
  const [referalSelected, setReferalSelected] = useState(
    referredId ? referredId : referral
  );
  const [referralChoice, setReferralChoice] = useState(
    checkout.referralChoice || '' // Initialize from checkout context
  );

  // Add a check for initial referral being Oola Life
  const isInitiallyOolaLife = referral === 'Oola Life';

  const updateContext = (target, value) => {
    setReferalSelected(value);
    setCheckout({ ...checkout, [target]: value });
  };

  const handleSearching = () => {
    setSearching((prevSearching) => !prevSearching);
  };

  const handleReferralChoice = (choice) => {
    setReferralChoice(choice);
    if (choice === 'own') {
      setSearching(false);
      // Update checkout context with referral choice and clear any selected referral
      setCheckout({
        ...checkout,
        referralChoice: choice,
        referredId: 'Oola Life',
        selectedReferral: null,
        isSearching: false
      });
    } else if (choice === 'find') {
      setSearching(true);
      // Update checkout context with referral choice but don't change referredId yet
      setCheckout({
        ...checkout,
        referralChoice: choice,
        isSearching: true
      });
    }
  };

  // Add useEffect to initialize referral choice in checkout context
  useEffect(() => {
    if (isInitiallyOolaLife) {
      // Always ensure referralChoice is initialized when Oola Life is the referral
      // Only set if it's not already set in the context
      if (!checkout.referralChoice) {
        setCheckout(prev => ({
          ...prev,
          referralChoice: ''
        }));
      } else {
        // Ensure local state matches context
        setReferralChoice(checkout.referralChoice);
      }
    }
  }, [isInitiallyOolaLife]);

  const isOolaLife = referalSelected === 'Oola Life';

  // Function to handle unselecting a referral
  const handleUnselectReferral = () => {
    // Only proceed if we're actually changing from a different referral
    if (referalSelected !== 'Oola Life') {
      // Reset URL parameter to corporphan
      const unselectUrl = new URL(window.location.href);
      unselectUrl.searchParams.set('referral', 'corporphan');
      window.history.replaceState({}, '', unselectUrl);

      // Reset states
      setReferalSelected('Oola Life');
      setReferralChoice('');
      setCheckout(prev => ({
        ...prev,
        referredId: 'Oola Life',
        selectedReferral: null,
        isSearching: false,
        cart: {
          ...prev.cart,
          referralId: 2,  // Reset to Oola Life ID
          discountRcID: 2 // Reset to Oola Life ID
        }
      }));
    }
  };

  // Update the click handler in the search results
  const handleReferralSelection = (referral) => {
    const referralDisplay = `${referral.firstName} ${referral.lastName}`;

    // Only proceed with updates if we're selecting a different referral
    if (referalSelected !== referralDisplay) {
      setReferalSelected(referralDisplay);
      setCheckout(prev => ({
        ...prev,
        referredId: referralDisplay,
        selectedReferral: {
          id: referral.customerID,
          firstName: referral.firstName,
          lastName: referral.lastName,
          state: referral.notes1,
          webAlias: referral.webAlias
        },
        isSearching: false,
        cart: {
          ...prev.cart,
          referralId: referral.customerID,  // Set the referralId to the selected customer's ID
          discountRcID: referral.customerID // Set the discountRcID to match
        }
      }));

      // Update URL parameter
      const selectionUrl = new URL(window.location.href);
      selectionUrl.searchParams.set('referral', referral.webAlias);
      window.history.replaceState({}, '', selectionUrl);

      setSearching(false);
    }
  };

  const searchReferrals = async (searchTerm) => {
    try {
      const response = await fetch(
        `${API_LOCAL_BASE_URL}api/Context/EnrollerSearch?query=${searchTerm}&shopUrl=${checkout.shopUrl}`
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setReferrals(data); // This will populate the referrals array
    } catch (error) {
      console.error('Error searching referrals:', error);
      sendErrorToSlack(error, {
        component: "SearchReferral-EnrollerSearch",
        userEmail: checkout.customer?.email,
        cartToken: checkout.cartToken
      });
    }
  };

  return (
    <>
      <Container fluid className="px-0">
        <Row className="mb-3">
          <Col xs={3} md={3} style={{ marginLeft: "-0.5rem" }}>
            Referred by
          </Col>
          <Col xs={6} md={7} className="text-black">
            {referalSelected}
            {/* Show unselect button only if initially Oola Life and a referral is selected */}
            {showReferralOptions && isInitiallyOolaLife && referalSelected !== 'Oola Life' && (
              <Button
                variant="link"
                size="sm"
                className="ms-2 text-danger"
                onClick={handleUnselectReferral}
              >
                ✕ Unselect
              </Button>
            )}
          </Col>
        </Row>

        {/* Only show the card if it's initially Oola Life AND showReferralOptions is true */}
        {showReferralOptions && isInitiallyOolaLife && (
          <Card className="mb-4 w-100">
            <Card.Body>
              <Row className="mb-3">
                <Col xs={12}>
                  <Form.Check
                    type="radio"
                    id="find-referral"
                    label="I was referred by someone"
                    name="referralChoice"
                    checked={referralChoice === 'find'}
                    onChange={() => handleReferralChoice('find')}
                    className="mb-2"
                  />
                  <Form.Check
                    type="radio"
                    id="own-referral"
                    label="I discovered Oola on my own"
                    name="referralChoice"
                    checked={referralChoice === 'own'}
                    onChange={() => handleReferralChoice('own')}
                  />
                </Col>
              </Row>

              {searching && (
                <Row className="mt-2">
                  <Col xs={12} md={12}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Search for name or ID."
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          if (e.target.value.length >= 3) {
                            searchReferrals(e.target.value);
                          } else {
                            setReferrals([]); // Clear results if search is too short
                          }
                        }}
                      />
                    </FloatingLabel>

                    {/* Add search results list */}
                    {referrals.length > 0 && (
                      <div className="search-results border rounded mb-3">
                        <ListGroup>
                          {referrals.map((referral) => (
                            <ListGroup.Item
                              key={referral.customerID}
                              action
                              onClick={() => handleReferralSelection(referral)}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <div>
                                {referral.firstName} {referral.lastName}
                                <small className="text-muted d-block">
                                  {referral.notes1}
                                </small>
                              </div>
                              <Button
                                variant="outline-primary"
                                size="sm"
                              >
                                Select
                              </Button>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        )}
      </Container>
    </>
  );
};

export default SearchReferral;
